Ext.define('PanelWizardKolumnyChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            title: Lang.rplkolumn,
            border: false,
            autoWidth: false,
            autoHeight: false,
            height: 457,
            items: [{
                xtype: 'panel',
                frame: false,
                border: false,
                autoWidth: false,
                autoHeight: false,
                height: 420,
                layout: 'hbox',
                layoutConfig: {
                    align: "stretch"
                },
                items: [new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    flex: 1,
                    title: Lang.dostepne,
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_from",
                    stateId: null,
                    stateful: false,
                    scrollable: true,
                    stripeRows: true,
                    autoHeight: false,
                    height: '100%',
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                }), {
                    width: 50,
                    height: 420,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: {
                        type: 'vbox',
                        pack: 'center',
                        align: 'middle'
                    },
                    items: [{
                        xtype: 'button',
                        text: ">",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: ">>",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid2.store.add(grid.store.getRange());
                            grid.store.removeAll();
                        }
                    }, {
                        xtype: 'button',
                        text: "<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: "<<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid.store.add(grid2.store.getRange());
                            grid2.store.removeAll();
                        }
                    }]
                }, new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    flex: 1,
                    title: Lang.wybrane,
                    height: '100%',
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_to",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                })]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var accessPropertyViewNameSterowniki = "access.view.sterowniki";
        var accessPropertyViewNameRozszerzenia = "access.view.rozszerzenia";
        var langmap = this.datad.mainCtrl.getKoolumnNazwaLangMap();
        var kolumnyyD = [];
        kolumnyyD.push({
            id: "czas",
            nazwa: langmap["czas"]
        });
        kolumnyyD.push({
            id: "stanZbiornika",
            nazwa: langmap["stan_zbiornika"]
        });
        if (Const.ENTERPRISEFLAG)
            kolumnyyD.push({
                id: "firma",
                nazwa: langmap["firma"]
            });
        kolumnyyD.push({
            id: "plyn",
            nazwa: langmap["plyn"]
        });
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
            kolumnyyD.push({
                id: "zbiornik",
                nazwa: langmap["zbiornik"]
            });
        kolumnyyD.push({
            id: "typ_tankowania",
            nazwa: langmap["typ_tankowania"]
        });
        kolumnyyD.push({
            id: "ile",
            nazwa: langmap["ile"]
        });
        if (!Const.BASICFLAG && !Const.STARTERFLAG)
            if (!Const.STANDARDFLAG)
                kolumnyyD.push({
                    id: "ilebezkompensacji",
                    nazwa: langmap["ilebezkompensacji"]
                });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "odbiorca",
                nazwa: langmap["odbiorca"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "pojazdTRW",
                nazwa: langmap["pojazdTRW"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "licznik",
                nazwa: langmap["licznik"]
            });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "dystans",
                    nazwa: langmap["dystans"]
                });
        if (!Const.SKPFLAG)
            if ((!Const.BASICFLAG && !Const.STARTERFLAG) || Const.CUSTOMMODFLAG == 'swimer')
                kolumnyyD.push({
                    id: "zuzycie100",
                    nazwa: langmap["zuzycie100"]
                });
        if (!Const.SKPFLAG)
            if ((!Const.BASICFLAG && !Const.STARTERFLAG) || Const.CUSTOMMODFLAG == 'swimer')
                kolumnyyD.push({
                    id: "normazuzycie100",
                    nazwa: langmap["normazuzycie100"]
                });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "motogodziny",
                        nazwa: langmap["motogodziny"]
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "czaspracy",
                        nazwa: langmap["czaspracy"]
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "zuzycie1h",
                        nazwa: langmap["zuzycie1h"]
                    });

        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "normazuzycie1h",
                        nazwa: langmap["normazuzycie1h"]
                    });
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        } else {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zdopis",
                nazwa: langmap["zdopis"]
            });
        if ((!Const.SKPFLAG && validPrivilegesOperatorComplex(accessPropertyViewNameSterowniki)) || Const.STARTERFLAG || Const.BASICFLAG || Const.STANDARDFLAG)
            kolumnyyD.push({
                id: "sterownik",
                nazwa: langmap["sterownik"]
            });
        if (!Const.SKPFLAG && validPrivilegesOperatorComplex(accessPropertyViewNameRozszerzenia) && !Const.BASICFLAG && !Const.STANDARDFLAG
            && !Const.STARTERFLAG)
            kolumnyyD.push({
                id: "rozszerzeniester",
                nazwa: langmap["rozszerzeniester"]
            });
        kolumnyyD.push({
            id: "operator",
            nazwa: langmap["operator"]
        });
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            kolumnyyD.push({
                id: "dostawca",
                nazwa: langmap["dostawca"]
            });
            kolumnyyD.push({
                id: "plynEIID",
                nazwa: langmap["plynEIID"]
            });
        }
        kolumnyyD.push({
            id: "grupa_odbiorca",
            nazwa: langmap["grupa_odbiorca"]
        });
        kolumnyyD.push({
            id: "grupa_pojazd",
            nazwa: langmap["grupa_pojazd"]
        });
        if (Const.COSTCOLUMNS) {
            kolumnyyD.push({
                id: "kosztcalosc",
                nazwa: langmap["kosztcalosc"]
            });
            kolumnyyD.push({
                id: "kosztlitr",
                nazwa: langmap["kosztlitr"]
            });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "pojazdTRWOpis",
                nazwa: langmap["pojazdTRWOpis"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "odbiorcaOpis",
                nazwa: langmap["odbiorcaOpis"]
            });
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG && !Const.CLOUDFLAG) {
            if (Const.ENABLEGPS) {
                if (validPrivilegesOperatorComplex(ViewGPSPojazdy.accessPropertyViewName) && validPrivilegesOperatorComplex(ViewGPSPojazdyhistory.accessPropertyViewName)) {
                    kolumnyyD.push({
                        id: "gpspojazdprzyrostbak",
                        nazwa: langmap["przyrost_bak"]
                    });
                    kolumnyyD.push({
                        id: "gpspojazdroznicabak",
                        nazwa: langmap["roznica_bak"]
                    });
                }
            }
        }
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            kolumnyyD.push({
                id: "pomiarPrzyrostZaladunek",
                nazwa: langmap["pomiar_przyrost_zbiornik_zaladunek"]
            });
            kolumnyyD.push({
                id: "rzeczywistyUbytekTankowanie",
                nazwa: langmap["pomiar_ubytek_zbiornik_tank"]
            });
        }
        var grid = Ext.getCmp(this.cid + "firmas_from");
        var grid2 = Ext.getCmp(this.cid + "firmas_to");
        var obss = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReport, "id");
        grid.store.loadData(obss.restls);
        grid2.store.loadData(obss.containsls);
    },
    reParseReportValidate: function () {
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        var grid = Ext.getCmp(this.cid + "firmas_from");
        var grid2 = Ext.getCmp(this.cid + "firmas_to");
        var colsNext = grid2.store.collect("id");
        if (colsNext.length == 0) {
            return Lang.trzeba_wybrac_kolumny;
        }
        this.datad.mainCtrl.repDef.columnsInReport = colsNext;
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
