Ext.define('WindowRejestrujSterowniki', {
    extend: 'Ext.ux.window.WindowExd',
    alias: 'widget.app.WindowRejestrujSterowniki',
    cls: "a-WindowRejestrujSterowniki",
    bodyStyle: "padding:10px",
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    border: true,
    width: 500,
    height: 300,
    layout: 'fit',
    title: Lang.rejestracja_nowego_sterownika.capitalise(),
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: "panelcardexd",
            itemId: "panelcardexdown",
            items: [{
                xtype: "panel",
                layout: "fit",
                itemId: "startForm",
                flex: 1,
                items: [{
                    xtype: "form",
                    scrollable: Ext.platformTags.touch,
                    defaults: {
                        labelAlign: "top"
                    },
                    flex: 1,
                    columnWidth: 1,
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    scrollable: true,
                    items: [{
                        xtype: 'textfieldexd',
                        id: this.cid + "serialnumber",
                        fieldLabel: Lang.numer_seryjny.capitalise(),
                        anchor: '0',
                        allowBlank: false,
                        maxLength: 64,
                        blankText: Lang.pole_nie_moze_byc_puste.capitalise()
                    }, {
                        xtype: 'textfieldexd',
                        id: this.cid + "pin",
                        fieldLabel: Lang.pin.capitalise(),
                        anchor: '0',
                        allowBlank: false,
                        maxLength: 4,
                        minLength: 4,
                        blankText: Lang.pole_nie_moze_byc_puste.capitalise(),
                        hidden: !Const.VENDORPINREQUIRED
                    }],
                    buttons: [{
                        text: Lang.dalej.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.performNextStep
                    }, {
                        text: Lang.zamknij.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.close
                    }]
                }]
            }, {
                xtype: "panel",
                layout: "fit",
                itemId: "resultForm",
                items: [{
                    xtype: "form",
                    scrollable: Ext.platformTags.touch,
                    defaults: {
                        labelAlign: "top"
                    },
                    columnWidth: 1,
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    scrollable: true,
                    items: [{
                        xtype: 'label',
                        id: this.cid + "resultText",
                        html: ''
                    }],
                    buttons: [{
                        text: Lang.zamknij.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.close
                    }]
                }]
            }]
        }];
        this.callParent(arguments);
    },
    show: function () {
        this.callParent(arguments);
    },
    afterRender: function () {
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    performNextStep: function () {
        var serialnumber = Ext.getCmp(this.cid + "serialnumber").getValue();
        if (serialnumber == null || serialnumber == '') {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        if (Const.VENDORPINREQUIRED && !Ext.getCmp(this.cid + "pin").validate()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var pin = Ext.getCmp(this.cid + "pin").getValue();
        var resultText = Ext.getCmp(this.cid + "resultText");
        this.mask(Lang.przetwarzanie_danych);
        performAjaxLPromise("rest/sterowniki/isObjectAvailable", {serialnumber: serialnumber, pin:pin})
            .then(d => {
                if (d) {
                    return new Promise(function (resolve, reject) {
                        var window;
                        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
                            window = Ext.create('WindowSterownikiBasicStd');
                        } else if (Const.OPTIMALFLAG) {
                            window = Ext.create('WindowSterownikiOpt');
                        } else {
                            window = Ext.create('WindowSterowniki');
                        }
                        let success = false;
                        window.showW(0, false, serialnumber);
                        window.on("closesuccess", function () {
                            success = true;
                        })
                        window.on("close", function () {
                            resolve(success);
                        })
                    });
                } else {
                    return "notexist";
                }
            })
            .then(d => {
                if (d == "notexist") {
                    resultText.setText(Lang.nie_istnieje_sterownik_o_takim_numerze_seryjnym.capitalise());
                } else if (d) {
                    resultText.setText(Lang.sterownik_zostal_zarejestrowany.capitalise());
                } else {
                    resultText.setText(Lang.blad_przy_rejestracji_sterownika.capitalise());
                }
                var layout = this.down("#panelcardexdown").getLayout();
                layout.setActiveItem("resultForm");
            })
            .catch(e => {
                handleCommonCallBackAjaxRPCError(e);
            })
            .finally(() => {
                this.unmask();
                if (this.callbackF)
                    this.callbackF();
            });
    },
    close: function () {
        this.callParent();
    }
});