Ext.define('ViewTankowania', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    id: "tankowania",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.tankowania",
        accessPropertyViewName: "access.view.tankowania",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
        accessPropertyViewNameSterowniki: "access.view.sterowniki"
    },
    cat: "p",
    text: Lang.tankowania,
    buttonIconCls: "iconTankowanie",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                id: this.id + "bnew",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/add"),
                hidden: Const.STARTERFLAG
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"),
                hidden: Const.STARTERFLAG
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDownload",
                text: Lang.import_zdarzen_z_pliku.capitalise(),
                scope: this,
                handler: this.importFromFile,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/add"),
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !Const.ENABLEMANUALIMPORTUSERTANK
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRestore",
                text: Lang.wymus_export_teraz.capitalise(),
                scope: this,
                handler: this.autoExportStart,
                hidden: !validPrivilegesOperatorComplex(WindowSysKonfaccessPropertyViewName) || !Const.ENABLEAUTOTANKEXPORT
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.callParent(arguments);
        this.showState = true;
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'temperaturasrednia',
                mapping: 'temperaturasrednia'
            }, {
                name: 'ilebezkompensacji',
                mapping: 'ilebezkompensacji'
            }, {
                name: 'licznik',
                mapping: 'licznik'
            }, {
                name: 'motogodziny',
                mapping: 'motogodziny'
            }, {
                name: 'licznikorg',
                mapping: 'licznikorg'
            }, {
                name: 'motogodzinyorg',
                mapping: 'motogodzinyorg'
            }, {
                name: 'sterownik',
                mapping: 'sterownik'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'zbiornik',
                mapping: 'zbiornik'
            }, {
                name: 'pojazd',
                mapping: 'pojazd'
            }, {
                name: 'firma',
                mapping: 'firma'
            }, {
                name: 'odbiorca',
                mapping: 'odbiorca'
            }, {
                name: 'kodawaryjnereczne',
                mapping: 'kodawaryjnereczne'
            }, {
                name: 'user',
                mapping: 'user'
            }, {
                name: 'typ',
                mapping: 'typ'
            }, {
                name: 'dystanssum',
                mapping: 'dystanssum'
            }, {
                name: 'czaspracysum',
                mapping: 'czaspracysum'
            }, {
                name: 'zuzycie100',
                mapping: 'zuzycie100'
            }, {
                name: 'zuzycie1h',
                mapping: 'zuzycie1h'
            }, {
                name: 'typ',
                mapping: 'typ'
            }, {
                name: 'srctyp',
                mapping: 'srctyp'
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: 'stanzbiornika',
                mapping: 'stanzbiornika',
                useNull: true,
                type: 'float'
            }, {
                name: 'kosztcalosc',
                mapping: 'kosztcalosc'
            }, {
                name: 'kosztlitr',
                mapping: 'kosztlitr'
            }, {
                name: 'nefWorkingParametersAsString',
                mapping: 'nefWorkingParametersAsString'
            }, {
                name: 'rzeczubytek',
                mapping: 'rzeczubytek'
            }, {
                name: 'liczniktotal',
                mapping: 'liczniktotal'
            }, {
                name: 'odbiorcagrupa',
                mapping: 'odbiorcagrupa'
            }, {
                name: 'pojazdgrupa',
                mapping: 'pojazdgrupa'
            }, {
                name: 'przeplywsredni',
                mapping: 'przeplywsredni'
            }, {
                name: 'gpspojazdprzyrostbak',
                mapping: 'gpspojazdprzyrostbak'
            }, {
                name: 'gpspojazdroznicabak',
                mapping: 'gpspojazdroznicabak'
            }, {
                name: 'odbiorcaeiid',
                mapping: 'odbiorcaeiid'
            }, {
                name: 'pojazdeiid',
                mapping: 'pojazdeiid'
            }, {
                name: 'gpsstanlicznika',
                mapping: 'gpsstanlicznika'
            }, "miedzymagazynowe", "exported", "isdolewka", "id", "stateflags"],
            proxy: {
                type: "ajax",
                url: "rest/tankowania/getList",
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        store.on("load", function (store) {
            Ext.defer(function () {
                var count = store.getTotalCount();
                var pageSize = store.getPageSize();
                var pageCount = Math.ceil(count / pageSize);
                var currentPage = store.currentPage;
                if (currentPage > pageCount && pageCount > 0) {
                    --store.currentPage;
                }
            }, 1000, this);
        }, this);
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.czas,
            flex: 3,
            sortable: true,
            dataIndex: 'czas',
            renderer: Ext.util.Format.dateRenderer(),
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.typ_tankowania,
            flex: 2,
            sortable: true,
            dataIndex: 'typ',
            renderer: function (v, meta, record, rowindex, colindex, store) {
                return LangComp.typy_tankowan[v];
            },
            filterable: true,
            filter: {
                type: 'list',
                options: [[Const.TANKOWANIE_ZWYKLE, LangComp.typy_tankowan[0]], [Const.TANKOWANIE_AWARYJNE, LangComp.typy_tankowan[3]], [Const.TANKOWANIE_RECZNE, LangComp.typy_tankowan[4]], [Const.TANKOWANIE_USER, LangComp.typy_tankowan[6]]]
            }
        }, {
            header: Lang.sterownik,
            flex: 2,
            sortable: true,
            dataIndex: 'sterownik',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.zbiornik,
            flex: 2,
            sortable: true,
            dataIndex: 'zbiornik',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.pojazd,
            flex: 2,
            sortable: true,
            dataIndex: 'pojazd',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.odbiorca,
            flex: 2,
            sortable: true,
            dataIndex: 'odbiorca',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.ile,
            flex: 1,
            sortable: true,
            dataIndex: 'ile',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.ilosc_nieskompensowana,
            width: 100,
            sortable: true,
            dataIndex: 'ilebezkompensacji',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.temperatura_srednia,
            width: 100,
            sortable: true,
            dataIndex: 'temperaturasrednia',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.stanzbiornika,
            flex: 1,
            sortable: true,
            dataIndex: 'stanzbiornika',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.dystans,
            width: 100,
            sortable: true,
            dataIndex: 'dystanssum',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.czaspracy,
            width: 100,
            sortable: true,
            dataIndex: 'czaspracysum',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.zuzycie100,
            flex: 1,
            sortable: true,
            dataIndex: 'zuzycie100',
            renderer: function (v) {
                return v == null ? "" : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG || Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.zuzycie1h,
            flex: 1,
            sortable: true,
            dataIndex: 'zuzycie1h',
            renderer: function (v) {
                return v == null ? "" : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG || Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.licznik,
            flex: 2,
            sortable: true,
            dataIndex: 'licznik',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.motogodziny,
            flex: 1,
            sortable: true,
            dataIndex: 'motogodziny',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.dodal,
            width: 145,
            sortable: true,
            dataIndex: 'user',
            filter: {
                type: 'string'
            },
            hidden: Ext.getViewportWidth() <= 1440,
            filterable: true
        }, {
            header: Lang.opis,
            width: 145,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            hidden: Ext.getViewportWidth() <= 1440,
            filterable: true
        }, {
            header: Lang.exported,
            width: 50,
            sortable: true,
            dataIndex: 'exported',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.stan_tankowania,
            flex: 2,
            sortable: true,
            dataIndex: 'stateflags',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: Ext.getViewportWidth() <= 1440
        }, {
            header: Lang.isdolewkadopelna,
            flex: 1,
            sortable: true,
            dataIndex: 'isdolewka',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            header: Lang.koszt,
            width: 100,
            sortable: true,
            dataIndex: 'kosztcalosc',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.rzeczywisty_ubytek,
            width: 100,
            sortable: true,
            dataIndex: 'rzeczubytek',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.cena_jednostkowa,
            width: 100,
            sortable: true,
            dataIndex: 'kosztlitr',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.nef_params,
            width: 200,
            sortable: false,
            dataIndex: 'nefWorkingParametersAsString',
            hidden: true,
            filterable: true
        }, {
            header: Lang.odbiorca_grupa,
            width: 200,
            sortable: true,
            dataIndex: 'odbiorcagrupa',
            filter: {
                type: 'string'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.pojazd_grupa,
            width: 200,
            sortable: true,
            dataIndex: 'pojazdgrupa',
            filter: {
                type: 'string'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.odbiorca_eiid,
            width: 200,
            sortable: true,
            dataIndex: 'odbiorcaeiid',
            filter: {
                type: 'string'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.pojazd_eiid,
            width: 200,
            sortable: true,
            dataIndex: 'pojazdeiid',
            filter: {
                type: 'string'
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.licznik_total,
            flex: 2,
            sortable: true,
            dataIndex: 'liczniktotal',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.przyrost_bak,
            flex: 2,
            sortable: true,
            dataIndex: 'gpspojazdprzyrostbak',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.roznica_bak,
            flex: 2,
            sortable: true,
            dataIndex: 'gpspojazdroznicabak',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.gps_stan_licznika,
            flex: 1,
            sortable: true,
            dataIndex: 'gpsstanlicznika',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            hidden: true,
            filterable: true
        }, {
            header: Lang.max_przeplyw,
            flex: 2,
            sortable: true,
            dataIndex: 'przeplywsredni',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.przesuniecie_miedzymagazynowe,
            width: 50,
            sortable: true,
            dataIndex: 'miedzymagazynowe',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || Ext.getViewportWidth() <= 1440
        }];
        if (!Const.ENTERPRISEFLAG) {
            var cfi = -1;
            for (var i = 0; i < cols.length; i++) {
                if (cols[i].dataIndex == "firma") {
                    cfi = i;
                    break;
                }
            }
            if (cfi > -1) {
                cols.splice(cfi, 1);
            }
        }
        if (Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "user");
            removeObjectFromArrayById(cols, "typ");
            removeObjectFromArrayById(cols, "odbiorca");
            removeObjectFromArrayById(cols, "licznik");
            removeObjectFromArrayById(cols, "odbiorcaeiid");
            removeObjectFromArrayById(cols, "pojazdeiid");
            removeObjectFromArrayById(cols, "odbiorcagrupa");
            removeObjectFromArrayById(cols, "pojazdgrupa");
        }
        if (Const.OPTIMALFLAG) {
            removeObjectFromArrayById(cols, "stateflags");
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "czaspracysum");
            removeObjectFromArrayById(cols, "zuzycie1h");
            removeObjectFromArrayById(cols, "motogodziny");
            removeObjectFromArrayById(cols, "temperaturasrednia");
            removeObjectFromArrayById(cols, "ilebezkompensacji");
            removeObjectFromArrayById(cols, "stateflags");
            removeObjectFromArrayById(cols, "exported");
            removeObjectFromArrayById(cols, "isdolewka");
            removeObjectFromArrayById(cols, "odbiorcaeiid");
            removeObjectFromArrayById(cols, "pojazdeiid");
            removeObjectFromArrayById(cols, "miedzymagazynowe");
        }
        if ((Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "dystanssum");
            removeObjectFromArrayById(cols, "zuzycie100");
        }
        if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            removeObjectFromArrayById(cols, "stanzbiornika");
        }
        if (!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewNameSterowniki)) {
            removeObjectFromArrayById(cols, "sterownik");
        }
        if (!Const.COSTCOLUMNS) {
            removeObjectFromArrayById(cols, "kosztcalosc");
            removeObjectFromArrayById(cols, "kosztlitr");
        }
        if (!Const.ENABLENEFPROCESSING || AppOPERATORROLA != Const.OPERATOR_P_SERWIS) {
            removeObjectFromArrayById(cols, "nefWorkingParametersAsString");
        }
        if (!validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) || !(Const.ENTERPRISEFLAG || Const.PROFLAG || Const.OPTIMALFLAG)) {
            removeObjectFromArrayById(cols, "rzeczubytek");
        }
        if (!validPrivilegesOperatorComplex(ViewGPSPojazdyhistory.accessPropertyViewName) || !Const.ENABLEGPS) {
            removeObjectFromArrayById(cols, "gpspojazdroznicabak");
            removeObjectFromArrayById(cols, "gpspojazdprzyrostbak");
            removeObjectFromArrayById(cols, "gpsstanlicznika");
        }
        if (AppOPERATORROLA != Const.OPERATOR_P_SERWIS || !(Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG)) {
            removeObjectFromArrayById(cols, "liczniktotal");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            autoWidth: true,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete")
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    getDownloadLsPdf: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/tankowania/downloadExportPdfOtherList", {
            format: "pdf",
            name: Lang.tankowania,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    getDownloadLsExcel: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/tankowania/downloadExportPdfOtherList", {
            format: "excel",
            name: Lang.tankowania,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    importFromFile: function () {
        sendAjaxDataJsonRpcL("rest/importtankfromfile/importTankFromFileProccessing", null, this, function (data) {
            if (data.status) {
                var dto = data.data;
                if (!dto.processing) {
                    let windowImportFromFile = Ext.create('WindowImportZdarzenFromFile');
                    windowImportFromFile.showW();
                } else {
                    let windowImportFromFileStatus = Ext.create('WindowImportZdarzenFromFileStatus');
                    windowImportFromFileStatus.showW();
                }
            }
        });
    },
    autoExportStart: function () {
        sendAjaxDataJsonRpcL("rest/tankowania/forceAutoExport", null, this, function (data) {
            if (data.status) {
                Ext.MessageBox.alert(Lang.operacja, Lang.proces_exportu_tankowan_start);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
            this.refreshDatastore();
        });
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null) {
            bedit.setDisabled(true);
            bdelete.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"));
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete"));
        }
        if (this.rowContext == null) {
            bedit2.setDisabled(true);
            bdelete2.setDisabled(true);
        } else {
            bedit2.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"));
            bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete"));
        }
        var roww = row || this.rowContext;
        var bnew = Ext.getCmp(this.id + "bnew");
        bnew.setDisabled(((Const.ENTERPRISEFLAG || Const.CLOUDFLAG) && AppFIRMACTX == null) || !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/add"));
        if (roww != null) {
            var typ = roww.get("typ");
            if (typ == Const.TANKOWANIE_USER) {
                bdelete.setDisabled(bdelete.disabled || false);
                bdelete2.setDisabled(bdelete2.disabled || false);
            } else {
                bdelete.setDisabled(true);
                bdelete2.setDisabled(true);
            }
        }
        if (Const.STARTERFLAG) {
            bnew.setVisible(false);
            bedit.setVisible(false);
            bedit2.setVisible(false);
            bdelete.setVisible(false);
            bdelete2.setVisible(false);
        }
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WAddEditUserTankowanieWindow');
        windowObject.on("wclose", function () {
            this.refreshDatastore();
        }, this);
        windowObject.showW(0);
    },
    usunObiekt: function () {
        var id = 0;
        var typ = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
                typ = this.rowContext.get("typ");
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
                typ = this.grid.getSelectionModel().getSelection()[0].get("typ");
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/tankowania/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    edytujObiekt: function () {
        var id = 0;
        var typ = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
                typ = this.rowContext.get("typ");
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
                typ = this.grid.getSelectionModel().getSelection()[0].get("typ");
            }
        }
        if (typ != 6) {
            var windowObject = Ext.create('WEditLicznikStateTankowanieWindow');
            windowObject.on("wclose", function () {
                this.refreshDatastore();
            }, this);
            windowObject.showW(id);
        } else {
            windowObject = Ext.create('WAddEditUserTankowanieWindow');
            windowObject.on("wclose", function () {
                this.refreshDatastore();
            }, this);
            windowObject.showW(id);
        }
    }
});
